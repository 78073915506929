/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import styled from "styled-components";
import { SubscriptionType } from "../../../util/misc";
import {
  ViewAllPlansScreen_YearlySelect,
  ViewAllPlansScreen_MonthlySelect,
} from "../../../services/mixpanel/mixpanel";
import theme from "../../../util/theme";
import {
  COUPON_DISCOUNT_DATA,
  mobileSize,
  PRIVACY_LINK,
  TERMS_LINK,
} from "../../../util/variables";
import Loading from "../../ui/loading";
import { useOnboardingContext } from "../onboarding/OnboardingContext";
interface Props {
  handleClick: (e: any, subscriptionType: SubscriptionType) => void;
  loading: boolean;
}

type PlanContainerProp = {
  handleClick: (e: any, subscriptionType: SubscriptionType) => void;
  width?: string;
  textCopy: string;
};

type DiscountedCardContainerProp = {
  price: number;
};

type CouponCardContainerProp = {
  amountOff: number;
  handleClick: (e: any, subscriptionType: SubscriptionType) => void;
};

const GroupCardContainer = () => {
  return (
    <ButtonContainer subType="group" width="100%">
      <Badge subType="discount">Free</Badge>
      <ButtonText>
        <b>Limited-Time Plan</b>
      </ButtonText>
      <ButtonText>Free for one year</ButtonText>
      <ButtonText>Available to the first 50 users who sign up</ButtonText>
    </ButtonContainer>
  );
};

const CouponCardContainer = ({ amountOff }: CouponCardContainerProp) => {
  const discountPercentage = Math.floor(100 - (99.99 - amountOff));

  return (
    <ButtonContainer subType="discount" width="100%">
      <Badge subType="discount">{discountPercentage}% off</Badge>
      <ButtonText>
        <b>Annual Plan</b>
      </ButtonText>
      <ButtonText>${99.99 - amountOff} for the first year</ButtonText>
      <ButtonText>Then $99.99 per year</ButtonText>
    </ButtonContainer>
  );
};

const DiscountedCardContainer = ({ price }: DiscountedCardContainerProp) => {
  const discountPercentage = Math.floor((1 - price / 99.99) * 100);

  return (
    <ButtonContainer subType="discount" width="100%">
      <Badge subType="discount">{discountPercentage}% off</Badge>
      <ButtonText>
        <b>Annual Plan</b>
      </ButtonText>
      <ButtonText>${price} per year</ButtonText>
    </ButtonContainer>
  );
};

const YearlyButtonContainer = ({ handleClick, width, textCopy }: PlanContainerProp) => {
  return (
    <ButtonContainer
      subType="yearly"
      onClick={e => {
        ViewAllPlansScreen_YearlySelect();

        handleClick(e, "yearly");
      }}
      width={width}
    >
      <Badge subType="yearly"> Most Popular </Badge>
      <ButtonText>
        <b>{textCopy}</b>
      </ButtonText>
      <ButtonText>$99.99</ButtonText>
      <ButtonText>Billed annually after 1 week</ButtonText>
    </ButtonContainer>
  );
};

const MonthlyButtonContainer = ({ handleClick, width, textCopy }: PlanContainerProp) => {
  return (
    <ButtonContainer
      subType="monthly"
      onClick={e => {
        ViewAllPlansScreen_MonthlySelect();
        handleClick(e, "monthly");
      }}
      width={width}
    >
      <ButtonText>
        <b>{textCopy}</b>
      </ButtonText>
      <ButtonText>$15.99</ButtonText>
      <ButtonText>Billed monthly</ButtonText>
    </ButtonContainer>
  );
};

const SubscriptionDetail = ({ handleClick, loading }: Props) => {
  // const { search } = data.location;
  // const urlParams = new URLSearchParams(search);
  // const couponParam = urlParams.get("cid") ?? undefined;

  const { couponId } = useOnboardingContext();

  return (
    <Container>
      <HeaderContainer>
        <b>
          SPECIAL OFFER
          <br /> FOR EARLY ADOPTERS
        </b>
      </HeaderContainer>
      <BodyContainer>
        <BodyContext>
          <BodySpan>Unlock</BodySpan> the full Imprint library. <br />
          <BodySpan>Discover</BodySpan> new titles, added frequently. <br />
          <BodySpan>Build</BodySpan> a learning habit
        </BodyContext>
        <SubscriptionOptionsContainer>
          {couponId ? (
            <CouponCardContainer
              handleClick={handleClick}
              amountOff={COUPON_DISCOUNT_DATA.find(c => c.id === couponId)?.discount ?? 0}
            />
          ) : (
            <YearlyButtonContainer handleClick={handleClick} textCopy="Start Your Free Trial" />
          )}
          <MonthlyButtonContainer
            handleClick={handleClick}
            textCopy="Start a Monthly Subscription"
          />
          {loading && <Loading />}
        </SubscriptionOptionsContainer>
        <TermsConditionContainer>
          <p>Recurring billing. Cancel anytime.</p>
          <div>
            <a href={TERMS_LINK}> Terms & Conditions </a>
            {"  "}•{"  "}
            <a href={PRIVACY_LINK}>Privacy Policy </a>
          </div>
        </TermsConditionContainer>
      </BodyContainer>
    </Container>
  );
};

export {
  SubscriptionDetail,
  GroupCardContainer,
  CouponCardContainer,
  DiscountedCardContainer,
  YearlyButtonContainer,
  MonthlyButtonContainer,
};

const Container = styled.section`
  height: 100%;
`;

const HeaderContainer = styled.section`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: ${theme.PRIMARY_COLOR};
`;

const BodyContainer = styled.div`
  width: 100%;
  margin-top: 31px;
`;

const BodyContext = styled.div`
  height: 96px;
  position: relative;
  font-family: ${theme.SECONDARY_FONT};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #292032;
  text-align: center;
`;

const BodySpan = styled.span`
  color: ${theme.PRIMARY_COLOR};
  font-weight: 600;
`;

const SubscriptionOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  justify-content: center;
  max-width: 327px;
  margin: auto;
  margin-top: 32px;
  @media ${mobileSize} {
    max-width: 100%;
  }
`;

type ButtonType = {
  subType: string;
  width?: string;
};

type BadgeType = {
  subType: string;
};

const ButtonContainer = styled.div<ButtonType>`
  width: ${props => (props.width ? props.width : "90%")};
  height: 88px;
  position: relative;
  margin: auto;
  background: ${props =>
    props.subType === "yearly" || props.subType === "discount" || props.subType === "group"
      ? theme.PRIMARY_COLOR
      : "#716B78"};
  border-radius: 12px;
  cursor: pointer;
  font-family: ${theme.SECONDARY_FONT};
  margin-bottom: 8px;
  padding: 10px;
`;

const Badge = styled.div<BadgeType>`
  position: absolute;
  width: 88px;
  height: 22px;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 10px;
  line-height: 22px;
  color: ${theme.PRIMARY_COLOR};
  background: ${theme.WHITE_COLOR};
  border-radius: 8px;
  font-weight: bold;
  right: 8px;
  top: 5px;
  text-align: center;
`;

const ButtonText = styled.p`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 14px;
  line-height: 24px;
  color: #fefefe;
  height: 24px;
  margin: 0px;
  margin-left: 5px;
`;

const TermsConditionContainer = styled.div`
  width: 100%;
  margin-top: 6rem;
  text-align: center;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 10px;
  line-height: 12px;
  p {
    margin: 0;
    color: #787880;
  }
  div {
    margin-top: 5px;
  }
  a {
    color: ${theme.PRIMARY_COLOR};
    text-decoration: underline;
  }

  @media screen and (max-height: 650px) {
    position: relative;
    margin: 20px 0;
  }
`;
