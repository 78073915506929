// THIS PAGE HAS BEEN DEPRECATED

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Formik, FormikProps } from "formik";
import { navigate } from "gatsby-link";
import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  GroupCardContainer,
  CouponCardContainer,
  DiscountedCardContainer,
  MonthlyButtonContainer,
  YearlyButtonContainer,
} from ".";
import {
  getCouponData,
  getStripeProductPrice,
  getStripeCheckoutUrl,
} from "../../../actions/stripeAction";
import { one, three, two } from "../../../content/assets";
import { getUserUID, groupSignup, register, login } from "../../../services/firebase";
import {
  group_signup_success,
  promo_signup_success,
  Subscribe_Account_AccountCreationSuccess,
  Subscribe_Account_EmailError,
  Subscribe_Account_EmailFocus,
  Subscribe_Account_PasswordError,
  Subscribe_Account_PasswordFocus,
  Subscribe_ChangePlan,
  Subscribe_Device_Android,
  Subscribe_Device_iPhone,
  Subscribe_Device_NotifyFormLink,
  Subscribe_Device_Other,
  Subscribe_GoToPayment,
} from "../../../services/mixpanel/mixpanel";
import { fbq, sendFBAddToCartEvent } from "../../../services/pixel";
import theme from "../../../util/theme";
import { COUPON_DISCOUNT_DATA, mobileSize } from "../../../util/variables";
import { useOnboardingContext } from "../onboarding/OnboardingContext";
import { getUserProfile } from "../../../services/firebase";
import Loading from "../../ui/loading";
import { setOneSignalAttributes } from "../../../services/onesignal";
import { AppText } from "../../ui";
import { Link } from "gatsby";
import { newUserSchema } from "../../../util/yup-schemas";
import { AppIcon } from "../../ui/AppIcon";
import LoadingSpinner from "../../ui/LoadingSpinner";

interface Props {
  subscriptionData: {
    type: string;
    header: string;
    submitText: string;
    priceId?: string;
    couponId?: string;
    groupId?: string;
  };
  handleClick: (e: any) => void;
  email?: string | null;
  search: string;
}

const handleMixpanelEvent = (error?: string, type: string) => {
  if (error) {
    if (type === "email") {
      Subscribe_Account_PasswordError();
    }
    if (type === "password") {
      Subscribe_Account_EmailError();
    }
  }
};

const NewUserForm = ({
  formik,
}: {
  formik: FormikProps<{
    email: string;
    password: string;
    isIOSUser: boolean;
  }>;
}) => {
  return (
    <div>
      <AllInputContainer>
        <ErrorContainer>
          <AppTextError>
            {`${formik.touched.email && formik.errors.email ? formik.errors.email : ""} `}
          </AppTextError>
        </ErrorContainer>
        <InputContainer>
          <Label hasValue={!!formik.values.email}>Email</Label>
          <StyledInput
            placeholder={formik.values.email ? "" : "Email"}
            name="email"
            type="text"
            value={formik.values.email}
            onChange={formik.handleChange}
            onFocus={Subscribe_Account_EmailFocus}
            onBlur={e => {
              handleMixpanelEvent(formik.errors.email, "email");
              formik.handleBlur(e);
            }}
          />
        </InputContainer>
      </AllInputContainer>

      <AllInputContainer>
        <ErrorContainer>
          <AppTextError>{`${formik.touched.password && !!formik.errors.password ? formik.errors.password : ""
            } `}</AppTextError>
        </ErrorContainer>

        <InputContainer>
          <Label hasValue={!!formik.values.password}>Password</Label>
          <StyledInput
            type="password"
            value={formik.values.password}
            placeholder={formik.values.password ? "" : "Password"}
            name="password"
            onChange={formik.handleChange}
            onFocus={Subscribe_Account_PasswordFocus}
            onBlur={e => {
              handleMixpanelEvent(formik.errors.password, "email");
              formik.handleBlur(e);
            }}
          />
        </InputContainer>
      </AllInputContainer>
    </div>
  );
};

const ExistingUserForm = ({ email }: { email: string }) => {
  return (
    <BodySubTitle>
      You’re logged in as {email} You can change your account information after subscribing!
    </BodySubTitle>
  );
};

export const UserSignUpForm = ({ subscriptionData, handleClick, email, search }: Props) => {
  const { onboardingAnswers, onboardingLandingPage, promoName } = useOnboardingContext();

  const [form, setForm] = useState({
    notification: false,
    initiallySubmiited: false,
  });
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [amountOff, setAmountOff] = useState(0);

  const deviceOption = ["iPhone", "Android", "Other"];

  useEffect(() => {
    const getPrice = async () => {
      try {
        const prod_price = await getStripeProductPrice(subscriptionData.priceId as string);
        console.log(prod_price);
        setPrice(prod_price);
      } catch (err) {
        navigate("/onboarding");
      }
    };

    const getAmountOff = async () => {
      try {
        const discountObj = COUPON_DISCOUNT_DATA.find(c => c.id === subscriptionData.couponId);
        if (discountObj) {
          setAmountOff(discountObj.discount);
          return;
        }

        const coupon = await getCouponData(subscriptionData.couponId as string);
        console.log(coupon.amount_off);
        setAmountOff(coupon.amount_off);
      } catch (err) {
        navigate("/onboarding");
      }
    };

    if (subscriptionData.type === "discount" && !!subscriptionData.priceId) {
      getPrice();
    }

    if (subscriptionData.type === "coupon" && !!subscriptionData.couponId) {
      getAmountOff();
    }
  }, [subscriptionData.type, subscriptionData.priceId, subscriptionData.couponId]);

  const renderPriceOption = () => {
    if (subscriptionData.type === "group") {
      return (
        <ChangePlanContainer>
          <GroupCardContainer />
        </ChangePlanContainer>
      );
    }

    if (subscriptionData.type === "coupon") {
      return (
        <ChangePlanContainer>
          <CouponCardContainer amountOff={amountOff} />
        </ChangePlanContainer>
      );
    }

    if (subscriptionData.type === "discount") {
      return (
        <ChangePlanContainer>
          <DiscountedCardContainer price={price} />
        </ChangePlanContainer>
      );
    }

    if (subscriptionData.type === "yearly") {
      return (
        <ChangePlanContainer>
          <YearlyButtonContainer
            handleClick={handlePriceChange}
            textCopy="Annual Plan"
            width="100%"
          />
          <ChangePlanText
            onClick={e => {
              Subscribe_ChangePlan();
              handleClick(e);
            }}
          >
            Change plan
          </ChangePlanText>
        </ChangePlanContainer>
      );
    }

    if (subscriptionData.type === "monthly") {
      return (
        <ChangePlanContainer>
          <MonthlyButtonContainer
            handleClick={handlePriceChange}
            textCopy={"Monthly Plan"}
            width={"100%"}
          />
          <ChangePlanText
            color="#2D7DD2"
            onClick={e => {
              Subscribe_ChangePlan();
              handleClick(e);
            }}
          >
            Change to <b>Annual Plan</b> and save
          </ChangePlanText>
        </ChangePlanContainer>
      );
    }

    return null;
  };

  const handlePriceChange = (e: any, subscriptionType: string) => {
    e.preventDefault();
  };

  const handleSubmit = async (
    e: any,
    formik: FormikProps<{
      email: string;
      password: string;
      isIOSUser: boolean;
    }>,
  ) => {
    e.preventDefault();
    const ready = readyForSubmit(formik, email);
    if (!ready) {
      return;
    }

    setLoading(true);
    sendFBAddToCartEvent({ subscription_type: subscriptionData.type, value: 0 });
    let submitEmail = "";
    let newUserId;

    // Check if the user already has a subscription
    if (email) {
      try {
        await login({ email, password: formik.values.password });
      } catch {
        alert(`Incorrect email or password. Please try again.`)
        setLoading(false);
        return;
      }

      const res = await getUserProfile();
      if (res.subscription_status !== "No active subscription") {
        alert(`You’ve already purchased this product. Please contact info@imprintapp.com if you have any questions or issues.`);
        setLoading(false);
        return;
      }

      console.log(res);
      submitEmail = email;
      await setOneSignalAttributes(res.uid, submitEmail);
    } else {
      // Register a new user
      // can't destructure because email is declared from destructured props
      const formEmail = formik.values.email;
      const { password } = formik.values;
      const response = await register({
        email: formEmail,
        password,
        onboardingResponses: onboardingAnswers,
        landingPage: onboardingLandingPage,
      });
      if (response.error) {
        formik.setErrors({ email: response.error });
        setLoading(false);
        return;
      }

      submitEmail = formEmail;
      newUserId = response.user.uid;
      await Subscribe_Account_AccountCreationSuccess(formEmail, newUserId);
      await setOneSignalAttributes(newUserId, submitEmail);
    }

    // handle limited, free sign up
    if (subscriptionData.type === "group" && subscriptionData.groupId) {
      try {
        const resMessage = await groupSignup(subscriptionData.groupId, newUserId);
        group_signup_success(subscriptionData.groupId);
        console.log(resMessage);
        navigate(
          `/subscription/complete?subscription_type=${subscriptionData.type}&user=${getUserUID()}`,
        );
      } catch (err) {
        alert(err.response.data);
      }

      setLoading(false);
      return;
    }
    //  /////

    if (promoName) {
      promo_signup_success(promoName);
    }

    Subscribe_GoToPayment(submitEmail);
    fbq("trackCustom", "Start_payment", { submitEmail });

    await getStripeCheckoutUrl({
      email: submitEmail || "",
      subscriptionType: subscriptionData.type,
      priceId: subscriptionData.priceId,
      couponId: subscriptionData.couponId,
      currency_code: subscriptionData.currency_code,
    });
    setLoading(false);
  };

  const handleDeviceChange = (
    formik: FormikProps<{
      email: string;
      password: string;
      isIOSUser: boolean;
    }>,
    device: string,
  ) => {
    if (device === "iPhone") {
      Subscribe_Device_iPhone();
    } else if (device === "Android") {
      Subscribe_Device_Android();
    } else if (device === "Other") {
      Subscribe_Device_Other();
    }

    if (device === "iPhone") {
      formik.setFieldValue("isIOSUser", true);
      setForm({ ...form, notification: false });
    } else {
      formik.setFieldValue("isIOSUser", false);

      setForm({
        ...form,
        notification: true,
      });
    }
  };

  const readyForSubmit = (
    formik: FormikProps<{
      email: string;
      password: string;
      isIOSUser: boolean;
    }>,
    existing_email?: string | null,
  ) => {
    if (existing_email) {
      return true;
    }

    const { email, password, isIOSUser } = formik.values;
    const { email: emailError, password: passwordError } = formik.errors;

    if (!!email && !!password && !emailError && !passwordError && isIOSUser) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <Header>
        <p>{subscriptionData.header}</p>
        <SubHeaderText>Get access to our entire library in just a few quick steps!</SubHeaderText>
      </Header>
      <WarningDiv>
        <AppIcon icon="exclamation-triangle" size={24} />
        <AppText style={{ margin: 0, marginLeft: 8 }}>This page is no longer used. To subscribe, please head to <Link to={`/subscription/${search}`}>this link</Link></AppText>
      </WarningDiv>
      <Formik
        initialValues={{
          email: "",
          password: "",
          isIOSUser: false,
        }}
        validationSchema={newUserSchema}
        onSubmit={values => { }}
      >
        {(
          formik: FormikProps<{
            email: string;
            password: string;
            isIOSUser: boolean;
          }>,
        ) => (
          <BodyContainer>
            <BodySection>
              <BodyTitleContainer>
                <BodyTitle>Your Plan</BodyTitle>
              </BodyTitleContainer>
              {price === 0 &&
                amountOff === 0 &&
                (subscriptionData.type === "discount" ||
                  subscriptionData.type === "coupon" ||
                  subscriptionData.type === "") ? (
                <Loading />
              ) : (
                renderPriceOption()
              )}
            </BodySection>
            <BodySection>
              <BodyTitleContainer>
                <TitleLogoImg src={one} alt="one" />
                <BodyTitle>Device Selection</BodyTitle>
              </BodyTitleContainer>
              <BodySubTitle>What is your primary mobile device?</BodySubTitle>
              <ErrorContainer>
                <AppTextError>{`${formik.errors.isIOSUser ? formik.errors.isIOSUser : ""
                  } `}</AppTextError>
              </ErrorContainer>
              {deviceOption.map((el, i) => {
                return (
                  <DeviceOptionContainer key={el}>
                    {email ? (
                      <DeviceReadOnlyOption>
                        <DeviceReadOnlyChildOption
                          checked={email && el === "iPhone" ? true : false}
                        />
                      </DeviceReadOnlyOption>
                    ) : (
                      <input
                        type="radio"
                        id={el}
                        name="drone"
                        value={el}
                        onClick={() => handleDeviceChange(formik, el)}
                      />
                    )}

                    <label htmlFor={el}>{el}</label>
                  </DeviceOptionContainer>
                );
              })}

              {form.notification && (
                <NonIOSContainer onClick={Subscribe_Device_NotifyFormLink}>
                  Imprint is only available on iOS devices at this time.{" "}
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfscwcoNpEAvbsr9na5RM-FzmXFDEaCbhBU4IFTfWMslStyFw/viewform">
                    Click here
                  </a>{" "}
                  to sign up to be notified about Android and other platforms.
                </NonIOSContainer>
              )}
            </BodySection>

            <BodySection>
              <BodyTitleContainer>
                <TitleLogoImg src={two} alt="two" />
                <BodyTitle>Create an Account</BodyTitle>
              </BodyTitleContainer>
              {email ? <ExistingUserForm email={email} /> : <NewUserForm formik={formik} />}
            </BodySection>
            {subscriptionData.type === "yearly" && (
              <BodySection>
                <BodyTitleContainer>
                  <TitleLogoImg src={three} alt="three" />
                  <BodyTitle>Payment</BodyTitle>
                </BodyTitleContainer>
                <BodySubTitle>
                  Next, add your payment info. You won’t be charged until the end of your trial.
                  Cancel anytime.
                </BodySubTitle>
              </BodySection>
            )}
            <p><em>This page is no longer used</em></p>
            {loading ? (
              <LoadingButton>
                <LoadingSpinner />
                <p>Loading</p>
              </LoadingButton>
            ) : (
              <Button
                // disabled={!readyForSubmit(formik, email)}
                disabled
                onClick={e => handleSubmit(e, formik)}
              >
                {subscriptionData.submitText}
              </Button>
            )}
          </BodyContainer>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 10rem;
`;

const Header = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #2d7dd2;
  margin: 0;
  @media ${mobileSize} {
    margin: 2em 0 0.5em;
  }
  margin-bottom: 8px;
`;

const SubHeaderText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-style: normal;
  font-weight: normal;
  color: #716b78;
  font-size: 16px;
  line-height: 24px;
  margin: auto;
  position: relative;
`;

const ChangePlanContainer = styled.div`
  margin: auto;
  max-width: 327px;
  font-family: ${theme.PRIMARY_FONT};

  @media ${mobileSize} {
    max-width: 100%;
  }
`;
const ChangePlanText = styled.p<{ color?: string }>`
  display: inline;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: ${props => (props.color ? props.color : "#716b78")};
  margin-left: 10px;
  :hover {
    cursor: pointer;
  }
`;

const BodySection = styled.div`
  margin-bottom: 60px;
  font-family: ${theme.PRIMARY_FONT};
`;

const BodyTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  & > * {
    margin-right: 16px;
  }
`;
const BodyTitle = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  line-height: 28px;
  color: #292032;
  font-weight: bold;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`;

const TitleLogoImg = styled.img`
  max-width: 32px;
  max-height: 32px;
`;

const BodySubTitle = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #716b78;
  max-width: 532px;
`;
const ErrorContainer = styled.div`
  width: 90%;
  margin: auto;
`;

const NonIOSContainer = styled.div`
  border-radius: 8px;
  padding: 16px 8px 16px 8px;
  background: #fefefe;
  margin: auto;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  line-height: 14px;
  margin-top: -10px;
  color: #292032;
  font-weight: bold;
  width: 327px;
  @media ${mobileSize} {
    width: 100%;
  }
  a {
    color: #0066cc;
  }
`;

const BodyContainer = styled.form`
  width: 100%;
  margin: auto;
  margin-top: 31px;
`;

const DeviceOptionContainer = styled.div`
  margin: auto;
  padding-bottom: 40px;
  display: flex;
  max-width: 327px;
  @media ${mobileSize} {
    max-width: 100%;
  }
  input {
    height: 20px;
    width: 20px;
    text-align: center;
  }
  label {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
  & > * {
    margin: auto 0;
    margin-right: 12px;
  }
`;

const DeviceReadOnlyOption = styled.div`
  position: relative;
  height: 16px;
  width: 16px;
  background: #fafafa;
  border: 2px solid #d2d5d8;
  box-sizing: content-box;
  border-radius: 50%;
  cursor: default;
`;

const DeviceReadOnlyChildOption = styled.div<{ checked: boolean }>`
  position: relative;
  margin: 2px;
  height: 12px;
  width: 12px;
  background: ${props => (props.checked ? "#D2D5D8" : "#fafafa")};
  border-radius: 50%;
`;

const AllInputContainer = styled.div`
  width: 327px;
  @media ${mobileSize} {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
`;

const InputContainer = styled.div`
  width: 327px;
  @media ${mobileSize} {
    width: 100%;
  }
  height: 64px;
  border-radius: 8px;
  background: #fafafa;
  border: 2px solid #d2d5d8;
  border: 2px solid #716b78;
  box-sizing: border-box;
  border-radius: 8px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > * {
    margin: 0 16px;
  }
  margin-bottom: 8px;
`;

type StyledLabelProps = {
  hasValue: boolean;
};

const Label = styled.label<StyledLabelProps>`
  display: ${props => (props.hasValue ? "block" : "none")};
  font-family: ${theme.PRIMARY_FONT};
  font-size: 13px;
  line-height: 13px;
  color: #8d8794;
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  border: none;
  background: none;
  outline: none;

  :active {
    border: none;
    outline: none;
  }
`;

type ButtonType = {
  disabled: boolean;
};

const Button = styled.button<ButtonType>`
  padding: 16px;
  width: 100%;
  background: ${props =>
    props.disabled
      ? "#E3E4E5"
      : "linear-gradient(90deg, rgba(115, 123, 253, 0.75) 1.68%, rgba(50, 123, 210, 0.75) 116.81%), #1E85E2;"};
  border-radius: 32px;
  margin: auto;
  margin-top: 10px;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  color: ${props => (props.disabled ? "#524D57" : "#fefefe")};
  cursor: ${props => (props.disabled ? "default" : "pointer")};
`;

const LoadingButton = styled.div`
  padding: 16px;
  width: 100%;
  font-family: ${theme.PRIMARY_FONT};
  margin: auto;
  margin-top: 10px;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background: #e3e4e5;
  color: #524d57;
  p {
    margin: 0 20px;
  }
`;

const AppTextError = styled.p`
  font-size: 12;
  font-family: ${theme.PRIMARY_FONT};
  color: red;
  margin: 2px;
  text-align: left;
`;

const WarningDiv = styled.div`
  display: flex;
  padding: 16px;
  background-color: #FFF2CD;
`;