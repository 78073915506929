/* eslint-disable @typescript-eslint/no-unused-vars */
import { navigate } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { SubscriptionDots } from "../../../content/assets";
import { SubscriptionType } from "../../../util/misc";
import { timelineTrialScreen_StartTrial } from "../../../services/mixpanel/mixpanel";
import theme from "../../../util/theme";
import { PRIVACY_LINK, TERMS_LINK } from "../../../util/variables";
import { AppLoadingButton } from "../../ui";

interface Props {
  handleClick: (e: any, subscriptionType: SubscriptionType) => void;
  loading: boolean;
}

export const SubscriptionMain = ({ handleClick, loading }: Props) => {
  return (
    <Container>
      <HeaderContainer>
        YOUR FREE TRIAL WITH <b>IMPRINT</b>
      </HeaderContainer>
      <BodyContainer>
        <BodyFlexContainer>
          <DotContainer>
            <MainCircle />
            <SmallDotContainer>
              <img src={SubscriptionDots} alt="img" />
            </SmallDotContainer>
            <GreyCircle />
            <SmallDotContainer>
              <img src={SubscriptionDots} alt="img" />
            </SmallDotContainer>
            <GreyCircle />
          </DotContainer>

          <div>
            <BodyContext style={{ marginTop: "-4px" }}>
              <BodyMain>NOW</BodyMain>
              <BodyDesc>
                Start your trial to <b>unlock the full Imprint library</b> today.
              </BodyDesc>
            </BodyContext>
            <BodyContext style={{ marginTop: "26px" }}>
              <BodyMain>IN 5 DAYS</BodyMain>
              <BodyDesc>
                <b>Receive</b> an email reminder that your free trial is ending.
              </BodyDesc>
            </BodyContext>

            <BodyContext style={{ marginTop: "25px" }}>
              <BodyMain>IN 7 DAYS</BodyMain>
              <BodyDesc>
                You’ll be <b>charged</b> for Imprint. Cancel anytime.
              </BodyDesc>
            </BodyContext>
          </div>
        </BodyFlexContainer>
      </BodyContainer>
      <PlanContainer>
        <TrialDetail>7-day free trial, then</TrialDetail>
        <TrialDetail>
          <b>$99.99 per year</b> ($8.33/month)
        </TrialDetail>
        <ButtonContainer>
          <AppLoadingButton
            type="button"
            disabled={loading}
            loading={loading}
            onClick={e => {
              timelineTrialScreen_StartTrial();
              handleClick(e, "yearly");
            }}
          >
            Try free and subscribe
          </AppLoadingButton>
        </ButtonContainer>
        <ViewAllPlanText onClick={async () => navigate("/subscription?page=Detail")}>
          View all plans
        </ViewAllPlanText>
      </PlanContainer>
      <TermsConditionContainer>
        <a href={TERMS_LINK}> Terms & Conditions </a>
        {"  "}•{"  "}
        <a href={PRIVACY_LINK}>Privacy Policy </a>
      </TermsConditionContainer>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 1rem;
`;

const HeaderContainer = styled.div`
  width: 225px;
  height: 56px;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${theme.PRIMARY_COLOR};
  margin: auto;
`;

const BodyContainer = styled.div`
  width: 227px;
  height: 252px;
  margin: auto;
  margin-top: 56px;
`;

const BodyFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* position: absolute; */
`;

const BodyContext = styled.div`
  max-width: 255px;
  width: 90%;
  position: relative;
  margin: 0px;
  margin-left: 16px;
`;
const DotContainer = styled.div`
  display: flex;
  flex-direction: column;
  img {
    margin-bottom: 10px;
  }
`;

const BodyMain = styled.p`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 18px;
  line-height: 20px;
  color: ${theme.PRIMARY_COLOR};
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 0px;
`;

const BodyDesc = styled.p`
  width: 100%;
  margin: 4px 0px;

  font-family: ${theme.SECONDARY_FONT};
  font-size: 14px;
  line-height: 20px;
  color: #292032;
`;

const MainCircle = styled.svg`
  width: 20px;
  height: 20px;
  left: 10px;
  top: 10px;
  border-radius: 100px;
  background: ${theme.PRIMARY_COLOR};
`;

const SmallDotContainer = styled.div`
  margin: 0px auto;
  margin-top: 5px;
  img {
    margin-bottom: 0px;
    height: 64px;
  }
`;
const GreyCircle = styled.svg`
  width: 20px;
  height: 20px;
  left: 10px;
  top: 10px;
  border-radius: 100px;
  background: #d2d5d8;
`;

const PlanContainer = styled.div`
  margin-top: 4rem;
`;

const TrialDetail = styled.p`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 18px;
  line-height: 20px;
  display: block;
  text-align: center;
  color: ${theme.PRIMARY_COLOR};
  margin: 2px auto;
`;

const ButtonContainer = styled.div`
  width: 90%;
  max-width: 295px;
  margin: 0 auto;
  margin-top: 10px;
`;

const ViewAllPlanText = styled.p`
  height: 20px;
  width: 100%;
  margin: auto;
  bottom: 18px;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  text-align: center;
  color: ${theme.PRIMARY_COLOR};
  cursor: pointer;
  margin: 30px auto;
`;

const TermsConditionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  text-align: center;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 12px;
  line-height: 14px;
  margin: 20px auto;
  a {
    color: ${theme.PRIMARY_COLOR};
    text-decoration: underline;
  }
`;
