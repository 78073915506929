import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { DesktopLogo } from "../../content/assets";
import { UserSignUpForm } from "../../components/PageSegments/subscription";
import { mobileSize } from "../../util/variables";
import { navigate } from "gatsby";
import { getCurrentUser } from "../../services/firebase";
import theme from "../../util/theme";
import { useOnboardingContext } from "../../components/PageSegments/onboarding/OnboardingContext";
import { landed_from_promo } from "../../services/mixpanel/mixpanel";
import { BasicLayout } from "../../layouts/BasicLayout";

// DEPRECATED
export default (data: any) => {
  const { state, search } = data.location;
  const { couponId, setPromoName } = useOnboardingContext();

  useEffect(() => {
    navigate(`/subscription/${search}`);
  }, []);

  const handleSubscriptionData = (subscriptionType: string) => {
    let subData = {
      type: "yearly",
      header: "Start your 7-day free trial",
      submitText: "Continue",
    };
  
    if (subscriptionType === "monthly") {
      subData = {
        type: "monthly",
        header: "Start your Imprint subscription",
        submitText: "Continue to Payment",
      };
    }
    return subData;
  };

  const getSubscriptionData = useCallback(() => {
    if (state?.subData) {
      console.log(state);
      return state.subData;
    }

    if (couponId) { //used for promos
      return {
        type: "coupon",
        header: "Start your special discounted plan",
        submitText: "Continue",
        couponId,
      };
    }

    if (search) {
      const urlParams = new URLSearchParams(search);
      const subscriptionType = urlParams.get("subscription_type");
      const id = urlParams.get("id");
      const cid = urlParams.get("cid");
      const gid = urlParams.get("gid");
      if (subscriptionType) {
        const subData = handleSubscriptionData(subscriptionType);
        return subData;
      }

      if (id) {
        return {
          type: "discount",
          header: "Start your special discounted plan",
          submitText: "Continue",
          priceId: id,
        };
      }

      if (cid) {
        return {
          type: "coupon",
          header: "Start your special discounted plan",
          submitText: "Continue",
          couponId: cid ?? couponId,
        };
      }

      if (gid) {
        return {
          type: "group",
          header: "Start your free, limited-time plan",
          submitText: "Continue",
          groupId: gid,
        };
      }
    }

    return {
      type: "yearly",
      header: "Start your 7-day free trial",
      submitText: "Continue",
    };
  }, [state, search]);

  const [subscriptionData, setSubscriptionData] = useState(getSubscriptionData());

  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  const [currentUser, setCurrentuser] = useState("");
  const resizeWindow = () => {
    setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const promo = urlParams.get("promo");
    if (promo) {
      landed_from_promo(promo);
      setPromoName(promo);
    }
  }, [setPromoName, search]);

  useEffect(() => {
    window.addEventListener("resize", resizeWindow);
    setCurrentuser(getCurrentUser());
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);

  useEffect(() => {
    setSubscriptionData(getSubscriptionData());
  }, [getSubscriptionData]);

  const handleSubscriptionDetailClick = (e: any) => {
    e.preventDefault();
    navigate("/subscription?page=Detail", {
      state: {
        page: "Detail",
      },
    });
  };

  return (
    <BasicLayout noIndex>
      <FlexContainer>
        <AppContainer height={windowSize.height}>
          <LogoContainer>
            <Logo src={DesktopLogo} />
            <Line />
          </LogoContainer>
          <Container>
            <UserSignUpForm
              subscriptionData={subscriptionData}
              handleClick={handleSubscriptionDetailClick}
              email={currentUser}
              search={search}
            />
          </Container>
        </AppContainer>
      </FlexContainer>
    </BasicLayout>
  );
};

const FlexContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #eef2f6;
  overflow: scroll;
  @media ${mobileSize} {
    justify-content: flex-start;
  }
`;

const AppContainer = styled.div<{ height: number }>`
  margin-top: 15%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-family: ${theme.PRIMARY_FONT};
  min-height: ${props => (props.height && props.height > 0 ? `${props.height * 0.9}px` : "80vh")};
  min-width: 343px;
  margin: auto;
  @media ${mobileSize} {
    width: 343px;
    margin-top: 0;
    min-height: ${props => (props.height && props.height > 0 ? `${props.height * 0.9}px` : "80vh")};
  }
`;

const Container = styled.div`
  height: 90%;
  width: 100%;
  position: relative;
`;

const Line = styled.hr`
  width: 588px;
  height: 0px;
  border: 2px solid #fefefe;
  margin: 40px 0px;

  @media ${mobileSize} {
    display: none;
    margin: 0;
  }
`;

const LogoContainer = styled.div`
  display: block;
  margin-top: 60px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  display: block;
  height: 24px;
  @media ${mobileSize} {
    height: 21px;
  }
`;
